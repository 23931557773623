.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%) translateZ(0);
  z-index: 1;

  li{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    opacity: 0;

    &:nth-child(1){ animation: loading1 .5s infinite; width: 130px; height: 130px }
    &:nth-child(2){ animation: loading2 .5s infinite; width: 130px; height: 130px; }
    &:nth-child(3){ animation: loading3 .5s infinite; }
    &:nth-child(4){ animation: loading4 .5s infinite; }
    &:nth-child(5){ animation: loading5 .5s infinite; }

  }

  svg{
    display: block;
    width: 100%;
    height: 100%;
  }

}

@media (min-width: 1920px) {
  .preloader {
    width: 150px;
    height: 150px;
  }
}

@-webkit-keyframes loading1 {
  0%, 19%   { opacity: 1 }
  20%, 100%   { opacity: 0 }
}
@keyframes loading1 {
  0%, 19%   { opacity: 1 }
  20%, 100%   { opacity: 0 }
}

@-webkit-keyframes loading2 {
  20%, 39%   { opacity: 1 }
  0%, 19%, 40%, 100%   { opacity: 0 }
}
@keyframes loading2 {
  20%, 39%   { opacity: 1 }
  0%, 19%, 40%, 100%   { opacity: 0 }
}

@-webkit-keyframes loading3 {
  40%, 59%   { opacity: 1 }
  0%, 39%, 60%, 100%   { opacity: 0 }
}
@keyframes loading3 {
  40%, 59%   { opacity: 1 }
  0%, 39%, 60%, 100%   { opacity: 0 }
}

@-webkit-keyframes loading4 {
  60%, 79%   { opacity: 1 }
  0%, 59%, 80%, 100%   { opacity: 0 }
}
@keyframes loading4 {
  60%, 79%   { opacity: 1 }
  0%, 59%, 80%, 100%   { opacity: 0 }
}

@-webkit-keyframes loading5 {
  80%, 100%   { opacity: 1 }
  0%, 79%   { opacity: 0 }
}
@keyframes loading5 {
  80%, 100%   { opacity: 1 }
  0%, 79%   { opacity: 0 }
}
